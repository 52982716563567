@font-face {
    font-family: 'LunaFont';
    src: url('../../assets/fuentes/Luna.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: #f5f5f5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo {
    font-weight: bold;
    font-size: 36px;
    font-family: 'LunaFont';
    margin: 0 auto; /* Centra el texto en el header */
}

.icons {
    display: flex;
    gap: 15px;
}

.icon {
    font-size: 24px;
    cursor: pointer;
}


