/* SoftwarePolicy.module.css */
.softwarePolicyContainer {
    padding: 20px;
    margin: auto;
    max-width: 800px;
    line-height: 1.6;
    font-size: 16px;
}

.softwarePolicyContainer h1 {
    text-align: center;
    margin-bottom: 20px;
}

.softwarePolicyContainer h2, .softwarePolicyContainer h3 {
    color: #333;
    margin-top: 20px;
}

.softwarePolicyContainer p {
    text-align: justify;
    margin-bottom: 10px;
}
