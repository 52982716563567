/* Footer.module.css */
.footerContainer {
    display: flex;
    justify-content: space-around;
    padding: 20px;
    background-color: #f5f5f5;
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}


.footerColumn {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.footerColumn h3 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
}

.footerColumn a {
    text-decoration: none;
    color: #000;
    font-size: 14px;
}
